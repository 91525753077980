let CON = {
    //SERVER_HOST:'http://192.168.10.198:8080',
    SERVER_HOST:'',

    //VIDEO_SERVER:'http://cdn.deshipt.com',
    //RESOURCE_SERVER:'http://cdn.deshipt.com',
    
    VIDEO_SERVER: 'http://media.deshipt.com',
    RESOURCE_SERVER:"",

    //male,female
    MALE:0,
    FEMALE:1,

    ///////////////ACTIONNAME///////////
    SAVE_USER_INFO:'Save_user_info_to_store',
    RESET_USER_INFO:'Reset_user_info_to_store',
    //Remains
    GET_REMAIN:'Get_remain_from_server',
    SAVE_REMAIN_INFO:'Save_remain_to_store',
    //PUBKEYS
    GET_PUBKEYS:'Get_pubkeys_from_server',
    SAVE_PUBKEY_INFO:'Save_pubkeys_to_store',
    //loading
    SET_LOADING_STATE:'Set_loading_status',

    ////SUTYPES////
    SUTYPE_MATHCOUNT:0,
    SUTYPE_CHOICE:1,
    SUTYPE_LLK:2,
};

export default CON;
export {CON};
